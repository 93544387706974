import React from 'react';
import Header from '../../components/header';
import { ROUTE_FLAG, WEBSITE } from '../../constants';
import {AboutUs} from '@medtrail/shared-ui';
const aboutUs = () => {

  return (
    <React.Fragment>
      <div className='headerContainer'>
        <Header active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage} customStyle='non-sticky'/>
        {/*<Header customStyle='sticky' active={null} routeFlag={ROUTE_FLAG.paymentPolicyPage}/>*/}
      </div>
      <AboutUs website={WEBSITE}/>
    </React.Fragment>
  );

};


export default aboutUs;
